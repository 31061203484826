// extracted by mini-css-extract-plugin
export var blogRoll = "blog-module--blog-roll--pKfQI";
export var categories = "blog-module--categories--J37WI";
export var categoriesList = "blog-module--categories-list--F-vZ6";
export var categoryLink = "blog-module--category-link--lqwz9";
export var explore = "blog-module--explore--k2rBI";
export var featureSection = "blog-module--feature-section--1V5N7";
export var loadMore = "blog-module--load-more--AOIlI";
export var loadMoreButton = "blog-module--load-more-button--9FLH7";
export var superscript = "blog-module--superscript---Sfgy";
export var title = "blog-module--title--TdY0b";
export var titleBlock = "blog-module--title-block--frGtQ";